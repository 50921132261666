import request from "@/utils/request";
const controller = "/VocationalEducation/JobSignRule";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/GetAllList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });

const saveSignRule = data =>
  request({
    url: `${controller}/SaveSignRule`,
    method: "post",
    data
  });
const deleteSignRule = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: { id }
  });
const changeStatus = params =>
  request({
    url: `${controller}/ChangeStatus`,
    method: "post",
    params
  });
const getWeekDays = params =>
  request({
    url: `${controller}/GetWeekDays`,
    method: "get",
    params
  });
export {
  getPageList,
  getAllList,
  getInfo,
  saveSignRule,
  deleteSignRule,
  changeStatus,
  getWeekDays
};
