<template>
  <div class="page-content">
    <el-row class="mb-16">
      <el-col :span="24" class="u-flex u-row-between">
        <div class="sertch inline">
          <el-input v-model="listQuery.KeyWord" placeholder="请输入关键字">
            <el-button
              slot="append"
              type="primary"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </div>
        <div class="btnContainer inline">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus-outline"
            @click="handleAdd"
            >新增</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-table
      v-loading="listLoading"
      :data="list"
      stripe
      style="width: 100%"
      height="640px"
    >
      <el-table-column
        width="260"
        label="规则名称"
        prop="Name"
      ></el-table-column>
      <!-- <el-table-column width="" label="是否拍照">
        <template slot-scope="scope">
          <span>{{scope.row.IsTakePhoto?'是':'否'}}</span>
        </template>
      </el-table-column> -->

      <el-table-column
        width=""
        label="生效日期"
        prop="StartDate"
      ></el-table-column>
      <el-table-column
        width=""
        label="失效日期"
        prop="EndDate"
      ></el-table-column>
      <el-table-column width="220" label="打卡时间" prop="SignTimeStr"
        ><template slot-scope="scope" v-if="scope.row.SignInTime">
          {{ scope.row.SignInTime + " - " + scope.row.SignOutTime }}
        </template>
      </el-table-column>
      <el-table-column width="100" label="是否定位">
        <template slot-scope="scope">
          <span>{{ scope.row.IsUploadLocation ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="规则状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.Status | statusFilter">
            {{ scope.row.Status | statusTextFilter }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="mini" icon="fa fa-search" @click="handeView(scope.row.Id)">查看</el-button> -->
          <el-button
            v-if="scope.row.Status === 1"
            type="text"
            size="mini"
            icon="el-icon-edit-outline"
            @click="handleChangeStatus(scope.row.Id, 0)"
            >启用</el-button
          >
          <el-button
            v-else
            type="text"
            size="mini"
            icon="el-icon-edit-outline"
            class="hover-danger"
            @click="handleChangeStatus(scope.row.Id, 1)"
            >停用</el-button
          >
          <el-button
            type="text"
            size="mini"
            icon="el-icon-edit"
            @click="handeEdit(scope.row.Id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="mini"
            icon="el-icon-delete"
            class="hover-danger"
            @click="handleDelete(scope.row.Id)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="u-flex u-row-right"
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.PageIndex"
      :limit.sync="listQuery.PageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getPageList, deleteSignRule, changeStatus } from "@/api/jobSignRule";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  name: "ListContentData",
  components: {
    Pagination
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "success",
        1: "danger"
      };
      return statusMap[status];
    },
    statusTextFilter(status) {
      const statusMap = {
        0: "启用",
        1: "停用"
      };
      if (statusMap[status]) {
        return statusMap[status];
      }
    }
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      list: [],
      total: 1,
      listLoading: false,
      listQuery: {
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10
      }
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.listLoading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.listQuery.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.listQuery.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        await this.getList();
      } catch (err) {
        console.log(err);
      } finally {
        this.listLoading = false;
      }
    },
    async getList() {
      const response = await getPageList(this.listQuery);
      if (response.IsSuccess) {
        this.list = response.Result.Data;
        this.total = response.Result.TotalCount;
      }
    },
    async handleChangeStatus(id, status) {
      const res = await changeStatus({ id, status });
      if (res.IsSuccess) {
        this.$message.success("修改成功");
        await this.initialize();
      } else {
        this.$message.error(res.Message);
      }
    },
    handleDelete(id) {
      this.$confirm("确定要删除所选数据吗?删除后将无法恢复！", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteSignRule(id).then(response => {
            if (response.IsSuccess) {
              this.initialize();
              this.$message.success("删除成功");
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleAdd() {
      this.$router.push(`/school/practice/signrule/create`);
    },
    handeEdit(id) {
      this.$router.push(`/school/practice/signrule/edit?id=${id}`);
    },
    handeView(id) {
      this.$router.push(`/school/practice/signrule/view?id=${id}`);
    }
  }
};
</script>

<style scoped>
.list_image {
  max-height: 40px;
  /* max-width: 100px; */
  display: block;
  margin: 0;
}
</style>
