function add0(m) {
  return m < 10 ? "0" + m : m;
}
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "danger",
        1: "info",
        2: "",
        3: "warning",
        4: "success"
      };
      return statusMap[status];
    }
  },
  methods: {
    presentTime(time) {
      //获取当前时间
      let yy = new Date().getFullYear();
      let mm = add0(new Date().getMonth() + 1);
      let dd = add0(new Date().getDate());
      let hh = add0(new Date().getHours());
      let mf = add0(new Date().getMinutes());
      // new Date().getMinutes() < 10;
      let ss = add0(new Date().getSeconds());

      return yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    },
    formartDate(timestamp, type, week) {
      var time = new Date(timestamp);
      if (!timestamp) {
        return "";
      }
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var w = time.getDay();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      var result = "";
      var numberChinese = "日一二三四五六";
      switch (type) {
        case "year":
          result = y;
          break;
        case "month":
          result = y + "-" + add0(m);
          break;
        case "day":
          result = y + "-" + add0(m) + "-" + add0(d);
          break;
        case "date":
          result = y + "-" + add0(m) + "-" + add0(d);
          break;
        case "hour":
          result = y + "-" + add0(m) + "-" + add0(d) + " " + add0(h);
          break;
        case "minutes":
          result =
            y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
          break;
        case "seconds":
          result =
            y +
            "-" +
            add0(m) +
            "-" +
            add0(d) +
            " " +
            add0(h) +
            ":" +
            add0(mm) +
            ":" +
            add0(s);
          break;
        case "h-m-s":
          result = add0(h) + ":" + add0(mm) + ":" + add0(s);
          break;
        case "h-m":
          result = add0(h) + ":" + add0(mm);
          break;
        case "all":
          result =
            y +
            "-" +
            add0(m) +
            "-" +
            add0(d) +
            " " +
            add0(h) +
            ":" +
            add0(mm) +
            ":" +
            add0(s);
          break;
        default:
          result =
            y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm);
          break;
      }
      if (week) {
        result += " 星期" + numberChinese[w];
      }
      return result;
    },
    getFileType(type) {
      if ("doc,docx,DOCX,DOC".indexOf(type) !== -1) {
        // console.log('word文档')
        return "word.png";
      } else if ("xls,xlsx,XLSX,XLS".indexOf(type) !== -1) {
        // console.log('Excel文档')
        return "excel.png";
      } else if ("ppt,pptx,PPTX,PPT".indexOf(type) !== -1) {
        // console.log('Excel文档')
        return "ppt.png";
      } else if ("jpg,jpeg,png,gif,JPG,JPEG,GIF".indexOf(type) !== -1) {
        // console.log('图片')
        return "picture.png";
      } else if ("pdf,PDF".indexOf(type) !== -1) {
        // console.log('pdf文件')
        return "pdf.png";
      } else {
        // console.log('默认')
        return "word.png";
      }
    },
    getFileSize(limit) {
      var size = "";
      if (limit < 0.1 * 1024) {
        // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + "K";
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "M";
      } else {
        // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "G";
      }
      var sizestr = size + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if (dec === "00") {
        // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    },
    // 扁平数据转化成树形结构 条件子级Id 父级Pid
    toTree(data, id, pid) {
      // eslint-disable-next-line space-before-function-paren
      data.forEach(function(item) {
        delete item.Children;
      });
      var map = {};
      // eslint-disable-next-line space-before-function-paren
      data.forEach(function(item) {
        map[item[id]] = item;
      });
      var val = [];
      // eslint-disable-next-line space-before-function-paren
      data.forEach(function(item) {
        var parent = map[item[pid]];
        if (parent) {
          (parent.Children || (parent.Children = [])).push(item);
        } else {
          val.push(item);
        }
      });
      return val;
    },
    // 删除最后一级children为空的属性
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children) {
          if (childs[i].Children.length) {
            this.deleteChildren(childs[i].Children);
          } else {
            delete childs[i].Children;
          }
        }
      }
      return arr;
    },
    getNowDate() {
      var datetime = this.formartDate(new Date());
      return datetime;
    },
    filterTimeStr(startTime, endTime) {
      if (
        startTime === "0001-01-01 00:00:00" &&
        endTime === "0001-01-01 00:00:00"
      ) {
        return "实习未开始";
      } else if (
        startTime !== "0001-01-01 00:00:00" &&
        endTime === "0001-01-01 00:00:00"
      ) {
        return `${this.formartDate(startTime, "day")} - 至今`;
      } else {
        return `${this.formartDate(startTime, "day")} 至 ${this.formartDate(
          endTime,
          "day"
        )} `;
      }
    }
  }
};
